import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private spinner: NgxSpinnerService) { }

  public open(){
    /** spinner starts on init */
    this.spinner.show();
  }

  public close(){
    /** spinner starts on init */
    this.spinner.hide();
  }

}
