import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://api.sop.billcentrix.com';
  // private apiUrl = 'https://devsop.billcentric.com';
  


  constructor(private http: HttpClient) {}

  authenticateCard(data: any): Observable<any> {
    return this.http.post(this.apiUrl + "/Auth3DS", data);
  }

  sale(data: any): Observable<any> {
    return this.http.post(this.apiUrl + "/Sale", data);
  }

  payment(data: any): Observable<any> {
    return this.http.post(this.apiUrl + "/Payment", data);
  }

  risk(data: any): Observable<any> {
    return this.http.post(this.apiUrl + "/Risk3DS", data);
  }
}
