import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfirmService } from 'src/app/services/app-confirm/app-confirm.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  basicForm: FormGroup;
  clientForm: FormGroup;
  public HOST: string = 'https://dev.hecbill.hecticus.com';

  years = Array.from({ length: 20 }, (_, i) => (i + new Date().getFullYear()).toString().slice(2)); // Generate a range of years (last two digits)
  months = [
    { value: '01', label: 'ENERO' },
    { value: '02', label: 'FEBRERO' },
    { value: '03', label: 'MARZO' },
    { value: '04', label: 'ABRIL' },
    { value: '05', label: 'MAYO' },
    { value: '06', label: 'JUNIO' },
    { value: '07', label: 'JULIO' },
    { value: '08', label: 'AGOSTO' },
    { value: '09', label: 'SEPTIEMBRE' },
    { value: '10', label: 'OCTUBRE' },
    { value: '11', label: 'NOVIEMBRE' },
    { value: '12', label: 'DICIEMBRE' }
  ];

  transactionId: string;
  iframeValue: string;
  iframeName: string;
  iframeUrl: string;
  redirectUrl: string;
  myIp: any;
  myHeight: number;
  myWidth: number;
  dataObject: any;

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private spinner: SpinnerService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private dialog: MatDialog,
    private confirmService: AppConfirmService
  ) {
    this.basicForm = new FormGroup({
      numberCreditCard: new FormControl('', [Validators.required]),
      nameHolderCreditCard: new FormControl('', Validators.required),
      expMonthCreditCard: new FormControl('', Validators.required),
      expYearCreditCard: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      cardType: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      postalCode: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      terminal: new FormControl('', Validators.required),  // Nuevo campo
      terminal3ds: new FormControl('', Validators.required) // Nuevo campo
    });

    this.clientForm = new FormGroup({
      Ds_SignatureVersion: new FormControl('HMAC_SHA256_V1'),
      Ds_MerchantParameters: new FormControl('', Validators.required),
      Ds_Signature: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.http.get('https://api.ipify.org/?format=json').subscribe(response => {
      console.log(response);
      this.myIp = response['ip'];
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
    });
  }

  generateInvoiceId(): string {
    return Math.floor(Math.random() * 1000000000).toString(); // Genera un número aleatorio de hasta 9 dígitos
  }

  send(): void {
    this.spinner.open();

    let idToSend = this.generateInvoiceId();

    const data = {
      terminal: this.basicForm.value.terminal, // Consumir el campo terminal
      callbackUrl: this.HOST,
      invoice: {
        invoiceId: idToSend,
        invoiceTotal: 0.01
      },
      creditCard: {
        creditCard: this.basicForm.value.numberCreditCard.trim(),
        creditCardDate: this.basicForm.value.expYearCreditCard + this.basicForm.value.expMonthCreditCard,
        customerId: 15507,
        cardholderName: this.basicForm.value.nameHolderCreditCard,
        cvv: this.basicForm.value.ccv,
        cardType: this.basicForm.value.cardType
      },
      extraData: {
        street: this.basicForm.value.street,
        city: this.basicForm.value.city,
        postalCode: this.basicForm.value.postalCode,
        phone: this.basicForm.value.phone,
        email: this.basicForm.value.email,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        ip: this.myIp
      }
    };

    const data3DS = {
      terminal: this.basicForm.value.terminal3ds, // Consumir el campo terminal 3ds
      callbackUrl: this.HOST,
      invoice: {
        invoiceId: idToSend,
        invoiceTotal: 0.01
      },
      creditCard: {
        creditCard: this.basicForm.value.numberCreditCard.trim(),
        creditCardDate: this.basicForm.value.expYearCreditCard + this.basicForm.value.expMonthCreditCard,
        customerId: 15507,
        cardholderName: this.basicForm.value.nameHolderCreditCard,
        cvv: this.basicForm.value.ccv,
        cardType: this.basicForm.value.cardType
      },
      extraData: {
        street: this.basicForm.value.street,
        city: this.basicForm.value.city,
        postalCode: this.basicForm.value.postalCode,
        phone: this.basicForm.value.phone,
        email: this.basicForm.value.email,
        screenHeight: this.myHeight,
        screenWidth: this.myWidth,
        ip: this.myIp
      }
    };

    console.log('Enviando datos a Auth3DS:', data3DS);
    this.dataObject = data;

    this.authService.authenticateCard(data3DS).subscribe(response => {
      console.log('Respuesta de Auth3DS:', response);
      if (response.responseCodeDescription == "transaction pending") { // CASOS PARA 3DS
        // Almacenar transactionId, iframeValue, iframeName, iframeUrl y redirectUrl de la respuesta
        this.transactionId = response.transactionId;
        this.iframeValue = response.threeDS.iframeValue;
        this.iframeName = response.threeDS.iframeName;
        this.iframeUrl = response.threeDS.iframeUrl;
        this.redirectUrl = response.threeDS.redirectUrl;
        console.log('Datos almacenados:');
        console.log('transactionId:', this.transactionId);
        console.log('iframeValue:', this.iframeValue);
        console.log('iframeName:', this.iframeName);
        console.log('iframeUrl:', this.iframeUrl);
        console.log('redirectUrl:', this.redirectUrl);

        // Enviar solicitud POST usando fetch con no-cors para el primer iframe
        this.sendIframeRequest(this.iframeUrl, this.iframeValue).then(() => {
          console.log('Solicitud del primer iframe enviada con éxito');
          console.log(this.sendIframeRequest)
          this.spinner.close(); // Detener el loader antes de abrir el popup
          // Abrir el segundo iframe en un popup
          this.openInteractiveIframePopup(this.redirectUrl, this.iframeValue);
        }).catch(err => {
          console.error('Error al enviar la solicitud del primer iframe:', err);
          this.spinner.close();
        });
      } else {
        this.spinner.close();
        console.log("NO RETORNO ESTATUS TRANSACTION PENDING PARA 3DS")
        console.log("USANDO EST DATA HACEMOS UN GET DEL PAYMENT: ", data);
        this.dataObject.transactionIdentifier = response.transactionId;
        this.authService.payment(this.dataObject).subscribe(response => {
          console.log("RESPONSE PAYMENT: ", response);
        }, err => {
          console.error("ERROR:  ", err);
        });
      }

    }, err => {
      console.error('Error en Auth3DS:', err);
      this.spinner.close();
      this.confirmService.confirm({ title: 'Error', message: 'Error en la autenticación de tarjeta' })
        .subscribe(result => {
          if (result) {
            console.log('Confirmación de error');
          }
        });
    });
  }

  // async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
  //   const formData = new FormData();
  //   formData.append('threeDSMethodData', iframeValue);

  //   console.log('Enviando datos al iframe URL:', url);
  //   console.log('Datos del formulario:', formData);

  //   const response = await fetch(url, {
  //     method: 'POST',
  //     body: formData,
  //     mode: 'no-cors', // Añadir modo no-cors
  //     credentials: 'include'
  //   });

  //   // R de CORS con no-cors
  //   console.log('Solicitud iframe enviada con modo no-cors.');
  // }

  async sendIframeRequest(url: string, iframeValue: string): Promise<void> {
    // Crear el cuerpo de la solicitud en formato URL-encoded
    const body = new URLSearchParams();
    body.append('threeDSMethodData', iframeValue);

    console.log('Datos que se envían al iframe:');
    console.log('URL:', url);
    console.log('Datos del formulario:', body.toString());

    // Enviar la solicitud POST al iframeUrl
    try {
        let response: Response;
        let attempts = 0;
        const maxAttempts = 5;
        const delay = 1000; // 0.5 segundos

        do {
            response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
                mode: 'no-cors', // Añadir modo no-cors
                credentials: 'include'
            });

            if (response.status === 200) {
                console.log('Solicitud iframe enviada con éxito.');
                break;
            } else {
                console.error(`Intento ${attempts + 1}: Error en la solicitud iframe:`, response.status, response.statusText);
            }

            attempts++;
            if (attempts < maxAttempts) {
                await new Promise(resolve => setTimeout(resolve, delay)); // Esperar 0.5 segundos antes del siguiente intento
            }
        } while (attempts < maxAttempts);

        if (attempts === maxAttempts && response.status !== 200) {
            console.error('Se alcanzó el número máximo de intentos y la solicitud no fue exitosa.');
        }
    } catch (error) {
        console.error('Error al enviar la solicitud del primer iframe:', error);
    }
}

  openInteractiveIframePopup(url: string, iframeValue: string): void {
    const dialogRef = this.dialog.open(InteractiveIframeDialog, {
      width: '600px',
      data: { url, iframeValue }
    });

    //  this.checkTransactionStatus(this.transactionId);
    console.log("USANDO ESTA DATA HACEMOS EL Payment: ", this.dataObject);
    this.dataObject.transactionIdentifier = this.transactionId;

    const intervalId = setInterval(() => {
      this.checkThreeDsStatusOnSOP();
    }, 5000);

    dialogRef.afterClosed().subscribe(result => {
      clearInterval(intervalId)
      console.log('El popup del iframe interactivo se ha cerrado');
      // Aquí puedes continuar con el siguiente paso después de que el usuario cierre el popup
      // this.authService.payment(this.dataObject).subscribe(response => {
      //   console.log("RESPONSE PAYMENT: ", response);
      // }, err => {
      //   console.error("ERROR:  ", err);
      // });

    });
  }

  checkThreeDsStatusOnSOP(){
    this.authService.risk(this.dataObject).subscribe(response =>{
      console.log("ESTE ES EL ESTATUS DEL 3DS: ", response)
    })
  }

  checkTransactionStatus(transactionId: string): void {

    this.subscriptionService.getTransactionStatus(transactionId).subscribe(response => {
      this.spinner.close();
      console.log('Estado de la transacción:', response);

      // if (response.result.result.code === '000.300.000') {
      //   this.confirmService.confirm({ title: 'Exito', message: 'Transacción completada correctamente' })
      //     .subscribe(result => {
      //       if (result) {
      //         console.log('Confirmación de éxito');
      //       }
      //     });
      // } else {
      //   this.confirmService.confirm({ title: 'Error', message: 'Transacción fallida' })
      //     .subscribe(result => {
      //       if (result) {
      //         console.log('Confirmación de error');
      //       }
      //     });
      // }
    }, err => {
      console.error('Error al consultar el estado de la transacción:', err);
      this.spinner.close();
    });
  }

  onlyNumberKey(event: any): boolean {
    return (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 8;
  }
}

@Component({
  selector: 'interactive-iframe-dialog',
  template: `
    <h1 mat-dialog-title>Complete la verificación</h1>
    <div mat-dialog-content>
      <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(data.url)" width="100%" height="400px" sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts"></iframe>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClose()">Cerrar</button>
    </div>
  `,
  styles: []
})
export class InteractiveIframeDialog {
  constructor(
    public dialogRef: MatDialogRef<InteractiveIframeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    public sanitizer: DomSanitizer
  ) { }

  onClose(): void {
    this.dialogRef.close();
    console.log("AQUI DEBERIA IR EL SALE")
  }
}
