
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private apiUrl = 'https://dev.hecbill.hecticus.com/suscription';

  constructor(private http: HttpClient) {}

  processStep1(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/aliado`, data);
  }

  processStep2(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/billcentrix`, data);
  }

  processChallenge(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/challenge`, data);
  }

  getTransactionStatus(transactionId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${transactionId}`);
  }
}
