

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<ngx-spinner type="ball-scale-multiple"> <p style="font-size: 20px; color: white">Espere...</p> </ngx-spinner>
<div style="position: relative;
z-index: 1;
display: block;
height: 100%;
overflow: auto;">

    <div  style="float: none;
    width: 100% !important;" class="main-content-wrap" id="main-content-wrap">

        <div style="padding-left: 15px;
        padding-right: 15px;
        padding-top: 5%;" id="rightside-content-hold" class="rightside-content-hold">

            <div style="max-width: 1400px;
            width: 100%;
            margin: 0 auto;" class="container-dynamic">

                <router-outlet></router-outlet>

            </div>

        </div>
        
    </div>

</div>








