<mat-card class="p-0">
  <mat-card-content>
    <form [formGroup]="basicForm" (ngSubmit)="send()">
      <mat-divider></mat-divider>
      <mat-card-title class="">
        <div style="padding-left: 0px;" class="card-title-text">Ingresa tus datos de pago</div>
      
      </mat-card-title>
<div style="gap: 20px; padding-bottom:30px;"><!-- Nuevo campo Terminal -->
  <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
    <mat-form-field class="full-width">
      <input
        matInput
        name="terminal"
        formControlName="terminal"
        placeholder="Terminal"
        value="">
    </mat-form-field>
    <small 
      *ngIf="basicForm.controls['terminal'].hasError('required') && basicForm.controls['terminal'].touched" 
      class="form-error-msg">Terminal es requerido</small>
  </div>

  <!-- Nuevo campo Terminal 3DS -->
  <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
    <mat-form-field class="full-width">
      <input
        matInput
        name="terminal3ds"
        formControlName="terminal3ds"
        placeholder="Terminal 3DS"
        value="">
    </mat-form-field>
    <small 
      *ngIf="basicForm.controls['terminal3ds'].hasError('required') && basicForm.controls['terminal3ds'].touched" 
      class="form-error-msg">Terminal 3DS es requerido</small>
  </div></div>
  <mat-divider></mat-divider>
      <div fxLayout="row wrap" fxLayoutGap="16px" style="padding-top: 20px;">
        
        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input
              matInput
              name="nameHolderCreditCard"
              formControlName="nameHolderCreditCard"
              placeholder="Titular"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['nameHolderCreditCard'].hasError('required') && basicForm.controls['nameHolderCreditCard'].touched" 
            class="form-error-msg">Titular es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input 
              (keypress)="onlyNumberKey($event)"
              matInput
              name="numberCreditCard"
              formControlName="numberCreditCard"
              placeholder="Tarjeta">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['numberCreditCard'].hasError('required') && basicForm.controls['numberCreditCard'].touched" 
            class="form-error-msg">Tarjeta es requerida</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <mat-label>Mes de Expiración</mat-label>
            <mat-select [formControl]="basicForm.controls['expMonthCreditCard']">
              <mat-option *ngFor="let month of months" [value]="month.value">{{ month.label }}</mat-option>
            </mat-select>
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['expMonthCreditCard'].hasError('required') && basicForm.controls['expMonthCreditCard'].touched" 
            class="form-error-msg">Mes es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <mat-label>Año de Expiración</mat-label>
            <mat-select [formControl]="basicForm.controls['expYearCreditCard']">
              <mat-option *ngFor="let year of years" [value]="year">{{ '20' + year }}</mat-option>
            </mat-select>
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['expYearCreditCard'].hasError('required') && basicForm.controls['expYearCreditCard'].touched" 
            class="form-error-msg">Año es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input 
              type="password"
              name="ccv"
              matInput
              formControlName="ccv"
              (keypress)="onlyNumberKey($event)"
              placeholder="CCV" 
              maxlength="3"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['ccv'].hasError('required') && basicForm.controls['ccv'].touched" 
            class="form-error-msg">CCV es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <mat-label>Tipo de Tarjeta</mat-label>
            <mat-select [formControl]="basicForm.controls['cardType']">
              <mat-option value="VISA">Visa</mat-option>
              <mat-option value="MASTER">Mastercard</mat-option>
            </mat-select>
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['cardType'].hasError('required') && basicForm.controls['cardType'].touched" 
            class="form-error-msg">Tipo de tarjeta es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input
              matInput
              name="street"
              formControlName="street"
              placeholder="Calle"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['street'].hasError('required') && basicForm.controls['street'].touched" 
            class="form-error-msg">Calle es requerida</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input
              matInput
              name="city"
              formControlName="city"
              placeholder="Ciudad"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['city'].hasError('required') && basicForm.controls['city'].touched" 
            class="form-error-msg">Ciudad es requerida</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input
              matInput
              name="postalCode"
              formControlName="postalCode"
              placeholder="Código Postal"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['postalCode'].hasError('required') && basicForm.controls['postalCode'].touched" 
            class="form-error-msg">Código Postal es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input
              matInput
              name="email"
              formControlName="email"
              placeholder="Email"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['email'].hasError('required') && basicForm.controls['email'].touched" 
            class="form-error-msg">Email es requerido</small>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="48" fxFlex.lt-md="48">
          <mat-form-field class="full-width">
            <input
              matInput
              name="phone"
              formControlName="phone"
              placeholder="Telefono"
              value="">
          </mat-form-field>
          <small 
            *ngIf="basicForm.controls['phone'].hasError('required') && basicForm.controls['phone'].touched" 
            class="form-error-msg">Telefono es requerido</small>
        </div>

        

      </div>

      <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" [disabled]="basicForm.invalid">Suscribirme</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
