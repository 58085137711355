import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallService } from 'src/app/services/call.service';
import { environment } from '../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-fac-popup',
  templateUrl: './fac-popup.component.html',
  styleUrls: ['./fac-popup.component.css']
})
export class FacPopupComponent implements OnInit {

  responseForm : any;
  public HOST: string = environment.backendUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private call: CallService,
    public dialogRef: MatDialogRef<FacPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.responseForm = this.data.form;
    if(!this.data.wait){
      const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          this.getStatustransaction();
        }, 1500);
      });
    }
  }

  getStatustransaction(){
    this.call.get(this.HOST + "/suscription/" + this.data.id).subscribe(response =>{

      if(response.result.result.code === "000.200.000"){
        this.getStatustransaction();
      }else{
        this.dialogRef.close();
      }

    }, err =>{
      console.log(err);
    })
  }

}
