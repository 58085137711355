import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16">{{ data.message }}</div>
    <div mat-dialog-actions>
    
    &nbsp;
    <span fxFlex></span>

    <div fxLayout="row" fxLayoutAlign="end center">
                <button 
                mat-raised-button 
                color="primary" 
                (click)="dialogRef.close(true)">Ok</button>

    </div>

    </div>`,
})
export class AppComfirmComponent {

  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}
}