import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from './views/form/form.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'form/:id', component: FormComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },  // Wildcard route for a 404 page
  { path: '**', component: NotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
